<template>
  <div class="max-w-md shadow-sm">
    <div
      class="flex items-center justify-between px-3 py-2 bg-gray-200 rounded shadow-xl"
      :class="{
        'bg-red-400': notification.type === 'error',
        'bg-green-400': notification.type === 'success',
      }"
    >
      <div v-if="notification.type === 'loading'" class="self-center">
        <div
          class="self-center w-6 h-6 mr-2 ease-linear border-2 border-t-2 border-gray-200 rounded-full loader"
        ></div>
      </div>
      <div class="self-center mr-2 break-words">
        {{ notification.text }}
      </div>
      <div v-if="notification.dismissible" class="self-center text-bg-gray-600">
        <div class="rounded-full cursor-pointer hover:bg-gray-300" @click="dismiss(notification)">
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 18L18 6M6 6L18 18"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  methods: {
    dismiss({ uniqid }) {
      this.$store.dispatch("deleteNotification", { uniqid });
    },
  },
};
</script>

<style lang="scss" scoped>
/* Spinner */
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
