<template>
  <div title="Preview">
    <router-link :to="to">
      <button
        class="w-8 h-8 p-1 text-xs text-white bg-gray-300 border rounded focus:outline-none focus:shadow-outline hover:bg-publicis-200"
      >
        <img src="/assets/icons/icon-arrow-right.svg" style="filter: opacity(0.5)" />
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["to"],
};
</script>

<style lang="scss" scoped></style>
