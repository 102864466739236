<template>
  <div class="p-4 mb-4">
    <div
      class="flex py-4 bg-gray-300 rounded-lg cursor-pointer hover:bg-teal-200"
      :class="{ 'rounded-b-none': expanded, shadow: expanded }"
      @click="expand()"
    >
      <div class="w-1/12">{{ account.id }}</div>
      <div class="w-2/12 text-left">
        <span class="font-bold">Name:</span>
        {{ account.name }}
      </div>
      <div class="w-3/12">
        <span class="font-bold">Number of users:</span>
        {{ account["number-users"] }}
      </div>
    </div>
    <div class="bg-gray-100 rounded-b-lg" v-show="expanded">
      <div
        class="py-1 font-bold bg-gray-200 cursor-pointer hover:bg-teal-200"
        @click="showInfo = !showInfo"
      >
        Billing Info
      </div>
      <div v-if="showInfo">
        <div class="pt-2">
          <div class="flex flex-wrap justify-center">
            <div class="w-full px-3 mb-6 md:w-3/12 md:mb-0">
              <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="name"
                >Name</label
              >
              <input
                class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-white focus:border-teal-100 focus:border-2"
                id="name"
                type="text"
                :value="info.billing_name"
                :disabled="authUser.role_id > 2"
              />
            </div>
            <div class="w-full px-3 mb-6 md:w-3/12 md:mb-0">
              <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="phone"
                >Phone</label
              >
              <input
                class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-white focus:border-teal-100 focus:border-2"
                id="phone"
                type="text"
                :value="info.billing_phone"
                :disabled="authUser.role_id > 2"
              />
            </div>

            <div class="w-full px-3 mb-6 md:w-1/12 md:mb-0">
              <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="timezone"
                >Timezone</label
              >
              <div class="relative">
                <select
                  class="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  id="timezone"
                  v-model="info.timezone_id"
                  :value="info.timezone_id"
                  :disabled="authUser.role_id > 2"
                >
                  <option v-for="timezone in timezones" :key="timezone.id" :value="timezone.id">
                    {{ timezone.name }}
                  </option>
                </select>
                <div
                  class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                >
                  <svg
                    class="w-4 h-4 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="w-full px-3 mb-6 md:w-1/12 md:mb-0">
              <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="currency"
                >Currency</label
              >
              <div class="relative">
                <select
                  class="block w-full px-4 py-3 pr-8 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  id="currency"
                  v-model="info.currency_id"
                  :disabled="authUser.role_id > 2"
                >
                  <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                    {{ currency.symbol }}
                  </option>
                </select>
                <div
                  class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
                >
                  <svg
                    class="w-4 h-4 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-center">
            <div class="w-full px-3 mb-6 md:w-3/12 md:mb-0">
              <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="address"
                >Address</label
              >
              <input
                class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-white focus:border-teal-100 focus:border-2"
                id="address"
                type="text"
                :value="info.billing_address1"
              />
            </div>
            <div class="w-full px-3 mb-6 md:w-2/12 md:mb-0">
              <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="city"
                >City</label
              >
              <input
                class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-white focus:border-teal-100 focus:border-2"
                id="city"
                type="text"
                :value="info.billing_city"
                :disabled="authUser.role_id > 2"
              />
            </div>
            <div class="w-full px-3 mb-6 md:w-2/12 md:mb-0">
              <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="state"
                >State</label
              >
              <input
                class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-white focus:border-teal-100 focus:border-2"
                id="state"
                type="text"
                :value="info.billing_state"
                :disabled="authUser.role_id > 2"
              />
            </div>
            <div class="w-full px-3 mb-6 md:w-2/12 md:mb-0">
              <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="country"
                >Country</label
              >
              <input
                class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-white focus:border-teal-100 focus:border-2"
                id="country"
                type="text"
                :value="info.billing_country"
                :disabled="authUser.role_id > 2"
              />
            </div>
            <div class="w-full px-3 mb-6 md:w-1/12 md:mb-0">
              <label class="block mb-1 text-xs font-bold text-gray-700 uppercase" for="zip"
                >Zip</label
              >
              <input
                class="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-white focus:border-teal-100 focus:border-2"
                id="zip"
                type="text"
                :value="info.billing_zip"
                :disabled="authUser.role_id > 2"
              />
            </div>
          </div>
          <div class="flex justify-center mb-4" v-if="authUser.role_id <= 2">
            <div>
              <button
                class="px-2 py-1 text-sm text-white bg-teal-500 border border-teal-500 rounded hover:bg-teal-700 hover:border-teal-700"
                type="submit"
              >
                Save
              </button>
            </div>
            <div class="flex justify-center pl-8">
              <button
                class="px-2 py-1 text-sm text-white bg-red-500 border border-red-500 rounded"
                type="button"
                @click="cancel()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="relative flex justify-start h-8 py-1 font-bold bg-gray-200 cursor-pointer hover:bg-teal-200"
        @click="showUsers = !showUsers"
      >
        <div
          class="m-auto"
          style="flex: 0 1 auto; position: absolute; left: 50%; transform: translateX(-50%)"
        >
          Users
        </div>
        <div
          class="flex pl-2 ml-auto mr-4 rounded-full hover:bg-gray-400"
          @click="showNewUserModal($event)"
          v-show="authUser.role_id <= 2"
        >
          <div>New User</div>
          <img src="/assets/icons/icon-plus.svg" alt />
        </div>
      </div>
      <div v-show="showUsers">
        <ul>
          <li v-for="user in account.users" :key="user.id" class="py-2">
            <div class="flex">
              <div class="w-1/12">{{ user.id }}</div>
              <div class="w-8/12 text-left">
                <span class="font-bold">Name:</span>
                {{ user.name }}
              </div>
              <div class="flex justify-end w-full pr-8" v-if="authUser.role_id <= 2">
                <div
                  class="mr-4 rounded-full cursor-pointer hover:bg-gray-300"
                  @click="showEditModal($event, user)"
                >
                  <img src="/assets/icons/icon-edit.svg" alt />
                </div>
                <div
                  class="bg-red-500 rounded-full cursor-pointer hover:bg-red-600"
                  @click="deleteUser(user)"
                >
                  <img src="/assets/icons/icon-minus-circle.svg" alt />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div
        class="relative flex justify-start h-8 py-1 font-bold bg-gray-200 cursor-pointer hover:bg-teal-200"
        @click="showInstagram = !showInstagram"
      >
        <div
          class="m-auto"
          style="flex: 0 1 auto; position: absolute; left: 50%; transform: translateX(-50%)"
        >
          Instagram
        </div>
        <div
          class="flex pl-2 ml-auto mr-4 rounded-full hover:bg-gray-400"
          @click="showInstagramModel($event)"
          v-show="authUser.role_id <= 2"
        >
          <div>New Profile</div>
          <img src="/assets/icons/icon-plus.svg" alt />
        </div>
      </div>
      <div v-show="showInstagram">
        <ul>
          <li v-for="profile in account.instagram_profiles" :key="profile.id" class="py-2">
            <div class="flex">
              <div class="w-1/12">{{ profile.id }}</div>
              <div class="w-8/12 text-left">
                <span class="font-bold">Name:</span>
                {{ profile.profileName }}
              </div>
              <div class="flex justify-end w-full pr-8" v-if="authUser.role_id <= 2">
                <div
                  class="bg-red-500 rounded-full cursor-pointer hover:bg-red-600"
                  @click="deleteProfile(profile)"
                >
                  <img src="/assets/icons/icon-minus-circle.svg" alt />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["account", "currencies", "timezones"],
  name: "account-details",
  data() {
    return {
      info: {},
      expanded: false,
      showUsers: true,
      showInfo: true,
      showInstagram: true,
    };
  },
  created() {
    this.info = Object.assign({}, this.account);
  },
  computed: {
    authUser() {
      return this.$store.getters["auth/authenticatedUser"]?.laravel;
    },
  },
  methods: {
    showInstagramModel(e) {
      e.stopPropagation();
      this.$emit("showInstagram", { account_id: this.account.id });
    },
    showNewUserModal(e) {
      e.stopPropagation();
      this.$emit("showNewUser", { action: "new", account_id: this.account.id });
    },
    showEditModal(e, u) {
      this.$emit("showEditUser", { action: "edit", user: u });
    },
    async deleteUser(u) {
      await this.$http.delete(`${this.$config.base_url}api/users/${u.id}`);
      this.$emit("deleteUser", u.id);
    },
    // deleteProfile(p) {},
    expand() {
      this.expanded = !this.expanded;
    },
    cancel() {
      this.info = Object.assign({}, this.account);
    },
  },
};
</script>

<style></style>
