import uniqid from "uniqid";

const notifications = {
  namespaced: true,
  state: {
    notifications: [],
  },
  mutations: {
    ADD_NOTIFICATION(state, data) {
      state.notifications = [...state.notifications, data];
    },
    DELETE_NOTIFICATION(state, { uniqid }) {
      state.notifications = state.notifications.filter((notif) => notif.uniqid !== uniqid);
    },
  },
  actions: {
    addNotification({ commit }, data) {
      data.uniqid = uniqid();
      commit("ADD_NOTIFICATION", data);
      if (data.timeout) {
        setTimeout(() => commit("DELETE_NOTIFICATION", { uniqid: data.uniqid }), data.timeout);
      }
      return data;
    },
    deleteNotification({ commit }, { uniqid }) {
      commit("DELETE_NOTIFICATION", { uniqid });
    },
  },
  getters: {
    notifications: (state) => state.notifications,
    notificationById: (state) => (id) => state.notifications.find((x) => x.id == id),
  },
};

export default notifications;
