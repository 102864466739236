<template>
  <div
    class="flex flex-col lg:w-6/12 sm:w-full rounded"
    :class="{ 'bg-red-200': v.$dirty && !v.required }"
  >
    <div class="flex justify-between">
      <label
        class="block text-gray-600 font-bold text-left mb-1 md:mb-0 p-2"
        :for="`${label}-selector`"
        v-text="label"
      />
      <div class="flex flex-col justify-end">
        <span
          v-if="v.$dirty && !v.required"
          class="text-sm p-1"
          :class="{ 'text-gray-600': v.$dirty && !v.required }"
        >
          This field is required
        </span>
      </div>
    </div>
    <div class="pb-2 px-2">
      <input
        class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-publicis-400"
        :class="{ 'border-red-300': v.$dirty && !v.required }"
        :id="`${label}-selector`"
        :type="type"
        :placeholder="placeholder"
        v-model="val"
        @input="v.$touch"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ValidatedInput",
  props: {
    label: { type: String },
    placeholder: {},
    value: { type: [String, Number], default: "" },
    v: { type: Object, required: true },
    type: { type: String, default: "text" },
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped></style>
