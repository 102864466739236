<template>
  <div id="preload" class="mb-4 p-4 rounded-lg"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#preload {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #edf2f7;
  background: linear-gradient(to right, #e2e8f0 8%, #cbd5e0 18%, #e2e8f0 33%);
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -900px 0;
  }
  100% {
    background-position: 900px 0;
  }
}
</style>
