<template>
  <div title="edit">
    <button
      class="px-2 py-1 text-xs text-white border rounded md:px-2 md:w-16 md:h-8 focus:outline-none focus:shadow-outline"
      :class="{
        'bg-red-500': this.field === 0,
        'hover:bg-red-400': this.field === 0,
        'bg-green-500': this.field === 1,
        'hover:bg-green-400': this.field === 1,
        'bg-blue-500': this.field === 2,
        'hover:bg-blue-400': this.field === 2,
      }"
      @click="statusChange()"
    >
      {{
        this.field === 0 ? "Inactive" : this.field === 1 ? "Active" : this.field === 2 ? "Test" : ""
      }}
    </button>
  </div>
</template>

<script>
export default {
  props: ["field", "link"],
  methods: {
    statusChange() {
      if (this.field !== 2) this.$emit("status");
    },
  },
};
</script>

<style></style>
