<template>
  <div class="flex flex-col">
    <h3>
      <input
        type="checkbox"
        class="form-checkbox"
        @change="$emit('check', { id: 'all', e: $event })"
      />
    </h3>
    <div v-for="item in data" class="py-2" :key="item.id">
      <input
        :data-id="item.id"
        type="checkbox"
        class="form-checkbox"
        @change="$emit('check', { id: item.id, e: $event })"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckColumn",
  props: ["data"],
};
</script>

<style scoped></style>
