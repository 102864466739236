<template>
  <div class="overflow-auto flex" v-if="data[0].data">
    <column
      class="pr-4"
      v-for="field in Object.keys(data[0].data)"
      :data="data"
      :field="{ name: `data.${field}`, title: camelCaseToNice(field) }"
      :key="field"
    />
  </div>
</template>

<script>
import Column from "@/components/DataTable/Column";

export default {
  name: "DataColumn",
  components: { Column },
  props: { data: { type: Array, default: () => [{}] } },
  methods: {
    camelCaseToNice(text) {
      return text.toUpperCase().replace("_", " ");
    },
  },
};
</script>

<style scoped></style>
