<template>
  <div class="mt-4 container justify-center mx-auto">
    <div class="rounded border border-gray-300 mb-16">
      <div class="py-2 font-black">{{ post.title }}</div>
      <hr />
      <div class="py-2">{{ post.content }}</div>
    </div>
    <transition name="fade">
      <new-comment
        :post="$route.params.id"
        @newComment="newComment($event)"
        v-if="showNewComment"
      ></new-comment>
    </transition>
    <comments :data="post.comments" :post="$route.params.id"></comments>
  </div>
</template>

<script>
import Comments from "@/components/Forum/Comments.vue";
import NewComment from "@/components/Forum/NewComment.vue";

export default {
  components: {
    NewComment,
    Comments,
  },
  data() {
    return {
      showNewComment: true,
      post: {},
      comments: {},
      forum: ForumService.getInstance(),
    };
  },
  mounted() {
    this.forum.subscribe(`posts/${this.$route.params.id}`, (x) => {
      this.post = x.val();
    });
  },
  methods: {
    newComment(e) {
      this.forum.writeInfo(`posts/${this.$route.params.id}/comments/${this.forum.randomId()}`, e);
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.125s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
