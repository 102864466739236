<template>
  <div id="comments" class="mt-4">
    <comment
      id="commentGroup"
      v-for="(comment, key) in data"
      :comment="comment"
      :key="key"
      :postId="post"
    ></comment>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Comment from "@/components/Forum/Comment.vue";

export default Vue.extend({
  components: { Comment },
  props: ["data", "post"],
  data() {
    return {
      // forum: ForumService.getInstance()
    };
  },
});
</script>

<style lang="scss" scoped>
#commentGroup:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
