const reporting = {
  namespaced: true,
  state: {
    filters: [],
    availableFilters: { advertiser: [], publisher: [] },
    availableData: [],
    side: "advertiser",
  },
  mutations: {
    resetFilters(state, newFilters) {
      state.filters = newFilters;
    },
    addFilter(state, filter) {
      state.filters.push({ id: "", filter: filter });
    },
    deleteFilter(state, key) {
      state.filters = state.filters.filter((_, k) => k !== key);
    },
    changeFilter(state, { index, key, value }) {
      state.filters[index][key] = value;
    },
    setAvailableFilters(state, filters) {
      Object.assign(state.availableFilters, filters);
    },
    setAvailableData(state, data) {
      state.availableData = data;
    },
    setSide(state, data) {
      state.side = data;
    },
  },
  actions: {
    setSide({ commit }, side) {
      commit("setSide", side);
    },
    resetFilters({ commit }, filters) {
      commit("resetFilters", filters);
    },
    addFilter({ commit }, filter) {
      commit("addFilter", filter);
    },
    deleteFilter({ commit }, key) {
      commit("deleteFilter", key);
    },
    changeFilter({ commit }, { index, key, value }) {
      commit("changeFilter", { index, key, value });
    },
    setAvailableFilters({ commit }, filters) {
      commit("setAvailableFilters", filters);
    },
    setAvailableData({ commit }, data) {
      commit("setAvailableData", data);
    },
  },
  getters: {
    filters: (state) => state.filters,
    availableFilters: (state) => {
      return state.availableFilters[state.side];
    },
    availableData: (state) => state.availableData,
  },
};

export default reporting;
