const routingRoutes = [
  {
    path: "",
    component: () => import("@/views/Reporting/Reports.vue"),
    name: "Reporting",
  },
  {
    path: "new",
    component: () => import("@/views/Reporting/NewReport.vue"),
    name: "NewReport",
  },
];

routingRoutes.forEach((x) => (x.meta = { side: "reporting" }));
export default routingRoutes;
