import Vuex from "vuex";

import LocalStorage from "@/services/LocalStorage";

import auth from "./auth";
import reporting from "./reporting";
import notifications from "./notifications";

const main = {
  state: {
    hidden: LocalStorage.getCustomizations("detailsHidden"),
  },
  mutations: {
    UPDATE_HIDDEN(state, status) {
      state.hidden = status;
    },
  },
  actions: {
    updateHidden({ commit }, status) {
      commit("UPDATE_HIDDEN", status);
      LocalStorage.updateCustomization({ detailsHidden: status });
    },
  },
  getters: {
    detailsHidden: (state) => state.hidden,
  },
};

export default new Vuex.Store({ modules: { reporting, auth, notifications, main } });
