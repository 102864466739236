const config = {
  appConfig: {
    base_url:
      process.env.NODE_ENV === "production"
        ? "https://devapi.contscale.com/"
        : "http://server.stories.cat/",
  },
  projectId: "bmindtracker",
  logging: {
    keyFilename: "",
  },
  errors: {
    key: "AIzaSyDDdYcLs2FY9jryt4XQ8aDEDIS0YlrZA4A",
  },
  firebaseConfig: {
    apiKey: "AIzaSyD0lw-RtpYPs0ejamIY1ZuqpSSRTHyJcss",
    authDomain: "bmindtracker.firebaseapp.com",
    databaseURL: "https://bmindtracker.firebaseio.com",
    projectId: "bmindtracker",
    storageBucket: "bmindtracker.appspot.com",
    messagingSenderId: "779924415603",
    appId: "1:779924415603:web:a5a6b2420c69698cec3606"
  },
};

export default config;
