<template>
  <div id="app">
    <router-view :key="$route.path" />
    <notifications :notifications="notifications" class="fixed top-0 right-0 mt-8 mr-8">
    </notifications>
  </div>
</template>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<script>
import { mapGetters } from "vuex";
import Notifications from "@/components/Notifications";

export default {
  components: {
    Notifications,
  },
  computed: {
    ...mapGetters({
      notifications: "notifications/notifications",
    }),
  },
};
</script>
