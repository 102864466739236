// Helper class
// Wrapper on top of the browser LocalStorage.

class LocalStorage {
  static getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  static writeUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  static getLaravelUser() {
    return JSON.parse(localStorage.getItem("user"))?.laravel;
  }

  static getFirebaseUser() {
    return JSON.parse(localStorage.getItem("user"))?.firebase;
  }

  static clearUser() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }

  static writeCustomizations(customizations) {
    localStorage.setItem("customizations", JSON.stringify(customizations));
  }

  static getCustomizations(key = null) {
    const customizations = JSON.parse(localStorage.getItem("customizations")) || {};
    if (key !== null) {
      if (customizations && Object.keys(customizations).includes(key)) {
        return customizations[key];
      } else {
        return null;
      }
    }
    return customizations;
  }

  static updateCustomization(customization) {
    const customizations = LocalStorage.getCustomizations();
    Object.assign(customizations, customization);
    LocalStorage.writeCustomizations(customizations);
  }

  updateLaravelUser(laravelUser) {
    const user = {
      laravel: laravelUser,
      firebase: LocalStorage.getFirebaseUser(),
    };
    LocalStorage.writeUser(user);
  }

  updateFirebaseUser(firebaseUser) {
    const user = {
      laravel: LocalStorage.getLaravelUser(),
      firebase: firebaseUser,
    };

    LocalStorage.writeUser(user);
  }
}

export default LocalStorage;
